
import { defineComponent } from "vue";
import {mapActions, mapGetters} from "vuex";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup"
import PopupMessage from "@/core/helpers/PopupMessage";

export default defineComponent({
  name: "ProfileDetails",

  components: {
    ErrorMessage,
    Field,
    Form,
  },

  data() {
    return {
      isSaving: false,
      updatedData: {
        first_name: null,
        last_name: null,
      },
    }
  },

  computed: {
    ...mapGetters({
      user: 'currentUser',
    }),

    profileDetailsValidator() {
      return Yup.object().shape({
        first_name: Yup.string()
          .required()
          .label("First name"),
        last_name: Yup.string()
          .required()
          .label("Last name"),
        // company: Yup.string()
        //   .required()
        //   .label("Company"),
        // phone: Yup.string()
        //   .required()
        //   .label("Contact phone"),
        // website: Yup.string().label("Webside"),
        // country: Yup.string()
        //   .required()
        //   .label("Country"),
        // language: Yup.string()
        //   .required()
        //   .label("Language"),
        // timezone: Yup.string()
        //   .required()
        //   .label("Timezone"),
        // currency: Yup.string()
        //   .required()
        //   .label("Currency")
      });
    },

    userFirstName: {
      get(): string {
        return this.updatedData.first_name ?? this.user?.first_name ?? null;
      },
      set(value) {
        this.updatedData.first_name = value;
      }
    },
    userLastName: {
      get(): string {
        return this.user?.last_name ?? null;
      },
      set(value) {
        this.updatedData.last_name = value;
      }
    },
  },

  methods: {
    ...mapActions({
      updateUser: 'user/update',
    }),

    getUpdatePayload() {
      const payload = {};
      Object.keys(this.updatedData).forEach(key => {
        if (this.updatedData[key] == null) return;
        payload[key] = this.updatedData[key];
      });
      return payload;
    },

    saveChanges() {
      const payload = this.getUpdatePayload();
      if(Object.keys(payload).length === 0) return;

      this.isSaving = true;
      this.updateUser(payload)
        .then(() => {
          PopupMessage.show('Profile Details updated successfully', 'success', 'Ok');
        })
        .catch(() => PopupMessage.show('Failed to update user settings', 'error', 'Ok'))
        .finally(() => {
          this.isSaving = false;
        });
    },
  }
});
