
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import * as Yup from "yup";
import PopupMessage from "@/core/helpers/PopupMessage";
import { ErrorMessage, Field, Form } from "vee-validate";

export default defineComponent({
  name: "EmailUpdate",

  components: {
    ErrorMessage,
    Field,
    Form
  },

  data() {
    return {
      isEditMode: false,
      isSaving: false,
      updatedData: {
        email: null,
        password: null,
      },
    }
  },

  computed: {
    ...mapGetters({
      user: 'currentUser',
    }),

    emailFormValidator() {
      return Yup.object().shape({
        email: Yup.string()
          .required()
          .email()
          .label("Email"),
        password: Yup.string()
          .required()
          .nullable()
          .label("Password")
      });
    },

    userEmail: {
      get(): string {
        return this.updatedData.email ?? this.user?.email ?? null;
      },
      set(value) {
        this.updatedData.email = value;
      }
    }
  },

  methods: {
    ...mapActions({
      updateUserEmail: 'user/updateEmail',
    }),

    getUpdatePayload() {
      const payload = {};
      Object.keys(this.updatedData).forEach(key => {
        if (this.updatedData[key] == null) return;
        payload[key] = this.updatedData[key];
      });
      return payload;
    },

    saveChanges() {
      const payload = this.getUpdatePayload();
      if(Object.keys(payload).length === 0) return;

      this.isSaving = true;
      this.updateUserEmail(payload)
        .then(() => {
          PopupMessage.show('Email updated successfully', 'success', 'Ok');
        })
        .catch(() => PopupMessage.show('Failed to update email', 'error', 'Ok'))
        .finally(() => {
          this.isSaving = false;
        });
    },
  }

});
