
import {defineComponent} from "vue";
// import * as Yup from "yup"
// import {mapActions, mapGetters} from "vuex";
// import PopupMessage from "@/core/helpers/PopupMessage";
import EmailUpdate from "@/views/profile/settings/internalAuth/EmailUpdate.vue";
import PasswordUpdate from "@/views/profile/settings/internalAuth/PasswordUpdate.vue";

export default defineComponent({
  name: "InternalAuth",

  components: {
    EmailUpdate,
    PasswordUpdate,
  },

  data() {
    return {
      form: {
        password: {
          visible: false,
          isSaving: false,
          data: {},
        },
      }
    };
  },


});
