
import {defineComponent} from "vue";
import * as Yup from "yup";
import PopupMessage from "@/core/helpers/PopupMessage";
import { ErrorMessage, Field, Form } from "vee-validate";
import {mapActions} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "DeleteAccount",

  components: {
    ErrorMessage,
    Field,
    Form
  },

  data() {
    return {
      isEditMode: false,
      isDeleteConfirmed: false,
      isDeleting: false,
      updatedData: {
        password: null
      }
    };
  },

  computed: {
    deactivationFormValidator() {
      return Yup.object().shape({
        password: Yup.string()
          .required()
          .nullable()
          .label("Password")
      });
    },
  },

  methods: {
    ...mapActions({
      deleteUserAccount: 'user/delete',
      logout: Actions.LOGOUT,
    }),

    setEditMode(isEditMode: boolean) {
      this.isDeleteConfirmed = false;
      this.isEditMode = isEditMode;
    },

    getUpdatePayload() {
      const payload = {};
      Object.keys(this.updatedData).forEach(key => {
        if (this.updatedData[key] == null) return;
        payload[key] = this.updatedData[key];
      });
      return payload;
    },

    deleteAccount() {
      const payload = this.getUpdatePayload();
      if(Object.keys(payload).length === 0) return;

      this.isDeleting = true;
      this.deleteUserAccount(payload)
        .then(() => {
          PopupMessage.show("Account successfully deleted.\nYou will be logged out.", 'success', 'Ok')
            .then(() => {
              this.logout();
              this.$router.push({ name: "sign-in" })
            });
        })
        .catch(() => PopupMessage.show('Failed to delete account', 'error', 'Ok'))
        .finally(() => {
          this.isDeleting = false;
        });
    },
  },

});
