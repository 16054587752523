<template>
  <!--begin::Email Address-->
  <div class="d-flex flex-wrap align-items-center mb-8">
    <div id="kt_signin_email" :class="{ 'd-none': isEditMode }">
      <div class="fs-4 fw-boldest mb-1">Email Address</div>
      <div class="fs-6 fw-bold text-gray-600">{{ userEmail }}</div>
    </div>

    <div
      id="kt_signin_email_edit"
      :class="{ 'd-none': !isEditMode }"
      class="flex-row-fluid"
    >
      <!--begin::Form-->
      <Form
        id="kt_signin_change_email"
        class="form"
        novalidate="novalidate"
        @submit="saveChanges"
        :validation-schema="emailFormValidator"
      >
        <div class="row mb-6">
          <div class="col-lg-6 mb-4 mb-lg-0">
            <div class="fv-row mb-0">
              <label
                for="eu_email"
                class="form-label fs-6 fw-bolder mb-3"
              >Enter New Email Address</label
              >
              <Field
                type="email"
                class="form-control form-control-lg form-control-solid fw-bold fs-6"
                id="eu_email"
                placeholder="Email Address"
                name="email"
                v-model="userEmail"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="email" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="fv-row mb-0">
              <label
                for="eu_password"
                class="form-label fs-6 fw-bolder mb-3"
              >Confirm Password</label
              >
              <Field
                type="password"
                class="form-control form-control-lg form-control-solid fw-bold fs-6"
                name="password"
                id="eu_password"
                v-model="updatedData.password"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="password" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <button
            id="kt_signin_submit"
            type="submit"
            ref="updateEmailButton"
            class="btn btn-primary me-2 px-6"
            :disabled="updatedData.email === null || updatedData.email === user.email"
            :data-kt-indicator="isSaving ? 'on': null"
          >
            <span class="indicator-label">
              Update Email
            </span>
            <span class="indicator-progress">
              Please wait...
              <span class="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          </button>
          <button
            id="kt_signin_cancel"
            type="button"
            class="btn btn-color-gray-400 btn-active-light-primary px-6"
            @click="isEditMode = !isEditMode"
          >
            Cancel
          </button>
        </div>
      </Form>
      <!--end::Form-->
    </div>
    <div
      id="kt_signin_email_button"
      :class="{ 'd-none': isEditMode }"
      class="ms-auto"
    >
      <button
        class="btn btn-light fw-boldest px-6"
        @click="isEditMode = !isEditMode"
      >
        Change Email
      </button>
    </div>
  </div>
  <!--end::Email Address-->
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import * as Yup from "yup";
import PopupMessage from "@/core/helpers/PopupMessage";
import { ErrorMessage, Field, Form } from "vee-validate";

export default defineComponent({
  name: "EmailUpdate",

  components: {
    ErrorMessage,
    Field,
    Form
  },

  data() {
    return {
      isEditMode: false,
      isSaving: false,
      updatedData: {
        email: null,
        password: null,
      },
    }
  },

  computed: {
    ...mapGetters({
      user: 'currentUser',
    }),

    emailFormValidator() {
      return Yup.object().shape({
        email: Yup.string()
          .required()
          .email()
          .label("Email"),
        password: Yup.string()
          .required()
          .nullable()
          .label("Password")
      });
    },

    userEmail: {
      get(): string {
        return this.updatedData.email ?? this.user?.email ?? null;
      },
      set(value) {
        this.updatedData.email = value;
      }
    }
  },

  methods: {
    ...mapActions({
      updateUserEmail: 'user/updateEmail',
    }),

    getUpdatePayload() {
      const payload = {};
      Object.keys(this.updatedData).forEach(key => {
        if (this.updatedData[key] == null) return;
        payload[key] = this.updatedData[key];
      });
      return payload;
    },

    saveChanges() {
      const payload = this.getUpdatePayload();
      if(Object.keys(payload).length === 0) return;

      this.isSaving = true;
      this.updateUserEmail(payload)
        .then(() => {
          PopupMessage.show('Email updated successfully', 'success', 'Ok');
        })
        .catch(() => PopupMessage.show('Failed to update email', 'error', 'Ok'))
        .finally(() => {
          this.isSaving = false;
        });
    },
  }

});
</script>

<style scoped>

</style>