<template>
  <!--begin::Delete Account-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div
      class="card-header border-0 cursor-pointer"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_delete"
      aria-expanded="true"
      aria-controls="kt_account_delete"
    >
      <div class="card-title m-0">
        <h3 class="fw-boldest m-0">Delete Account</h3>
      </div>
    </div>
    <!--end::Card header-->

    <!--begin::Content-->
    <div id="kt_account_delete"
         class="collapse show"
         v-if="isEditMode"
    >
      <!--begin::Form-->
      <Form
        id="kt_account_delete_form"
        class="form"
        @submit="deleteAccount"
        :validation-schema="deactivationFormValidator"
      >
        <!--begin::Card body-->
        <div class="card-body border-top p-9">
          <div
            class="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6"
          >
            <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
              <inline-svg src="/media/svg/icons/Code/Warning-1-circle.svg" />
            </span>
            <!--begin::Wrapper-->
            <div class="d-flex flex-stack flex-grow-1 ">
              <!--begin::Content-->
              <div class=" fw-bold">
                <h4 class="text-gray-800 fw-bolder">
                  You Are Deactivating Your Account
                </h4>
              </div>
              <!--end::Content-->
            </div>
            <!--end::Wrapper-->
          </div>

          <div class="row mb-6">
            <!--begin::Form input row-->
            <div class="col-lg-6 mb-4 mb-lg-0">
              <div class="form-check form-check-solid fv-row">
                <input
                  name="delete"
                  class="form-check-input"
                  type="checkbox"
                  v-model="isDeleteConfirmed"
                  id="da_delete"
                />
                <label class="form-check-label fw-bold ps-2 fs-6" for="da_delete">
                  Confirm Account Deactivation
                </label>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="fv-row mb-0">
                <label
                  for="da_password"
                  class="form-label fs-6 fw-bolder mb-3">
                  Confirm Password
                </label>
                <Field
                  type="password"
                  class="form-control form-control-lg form-control-solid fw-bold fs-6"
                  name="password"
                  id="da_password"
                  v-model="updatedData.password"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="password" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end::Form input row-->
        </div>
        <!--end::Card body-->

        <!--begin::Card footer-->
        <div class="card-footer d-flex justify-content-end py-6 px-9">
          <button
            id="kt_account_delete_account_submit"
            ref="submitButton5"
            type="submit"
            class="btn btn-danger fw-bold"
            :disabled="!isDeleteConfirmed"
          >
            <span class="indicator-label">
              Confirm
            </span>
            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>

          <button class="btn btn-color-gray-400 btn-active-light-primary px-6 ms-2"
                  @click="setEditMode(false)">
            <span class="indicator-label">
              Cancel
            </span>
          </button>
        </div>
        <!--end::Card footer-->
      </Form>
      <!--end::Form-->
    </div>
    <!--end::Content-->

    <div class="card-footer d-flex justify-content-end py-6 px-9" v-if="!isEditMode">
      <button class="btn btn-secondary fw-bold"
              @click="setEditMode(true)">
      <span class="indicator-label">
        Delete Account
      </span>
      </button>
    </div>

  </div>
  <!--end::Delete Account-->
</template>

<script lang="ts">
import {defineComponent} from "vue";
import * as Yup from "yup";
import PopupMessage from "@/core/helpers/PopupMessage";
import { ErrorMessage, Field, Form } from "vee-validate";
import {mapActions} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "DeleteAccount",

  components: {
    ErrorMessage,
    Field,
    Form
  },

  data() {
    return {
      isEditMode: false,
      isDeleteConfirmed: false,
      isDeleting: false,
      updatedData: {
        password: null
      }
    };
  },

  computed: {
    deactivationFormValidator() {
      return Yup.object().shape({
        password: Yup.string()
          .required()
          .nullable()
          .label("Password")
      });
    },
  },

  methods: {
    ...mapActions({
      deleteUserAccount: 'user/delete',
      logout: Actions.LOGOUT,
    }),

    setEditMode(isEditMode: boolean) {
      this.isDeleteConfirmed = false;
      this.isEditMode = isEditMode;
    },

    getUpdatePayload() {
      const payload = {};
      Object.keys(this.updatedData).forEach(key => {
        if (this.updatedData[key] == null) return;
        payload[key] = this.updatedData[key];
      });
      return payload;
    },

    deleteAccount() {
      const payload = this.getUpdatePayload();
      if(Object.keys(payload).length === 0) return;

      this.isDeleting = true;
      this.deleteUserAccount(payload)
        .then(() => {
          PopupMessage.show("Account successfully deleted.\nYou will be logged out.", 'success', 'Ok')
            .then(() => {
              this.logout();
              this.$router.push({ name: "sign-in" })
            });
        })
        .catch(() => PopupMessage.show('Failed to delete account', 'error', 'Ok'))
        .finally(() => {
          this.isDeleting = false;
        });
    },
  },

});
</script>

<style scoped>

</style>