<template>
  <!--begin::Sign-in Method-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div
      class="card-header border-0 cursor-pointer"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_signin_method"
    >
      <div class="card-title m-0">
        <h3 class="fw-boldest m-0">Sign-in Method</h3>
      </div>
    </div>
    <!--end::Card header-->

    <!--begin::Content-->
    <div id="kt_account_signin_method" class="collapse show">
      <!--begin::Card body-->
      <div class="card-body border-top p-9">
        <email-update />
        <password-update />
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Sign-in Method-->
</template>

<script lang="ts">
import {defineComponent} from "vue";
// import * as Yup from "yup"
// import {mapActions, mapGetters} from "vuex";
// import PopupMessage from "@/core/helpers/PopupMessage";
import EmailUpdate from "@/views/profile/settings/internalAuth/EmailUpdate.vue";
import PasswordUpdate from "@/views/profile/settings/internalAuth/PasswordUpdate.vue";

export default defineComponent({
  name: "InternalAuth",

  components: {
    EmailUpdate,
    PasswordUpdate,
  },

  data() {
    return {
      form: {
        password: {
          visible: false,
          isSaving: false,
          data: {},
        },
      }
    };
  },


});
</script>

<style scoped>

</style>