
import { defineComponent } from "vue";
import {mapActions, mapGetters} from "vuex";
import * as Yup from "yup";
import PopupMessage from "@/core/helpers/PopupMessage";
import { ErrorMessage, Field, Form } from "vee-validate";

export default defineComponent({
  name: "PasswordUpdate",

  components: {
    ErrorMessage,
    Field,
    Form
  },

  data() {
    return {
      isEditMode: false,
      isSaving: false,
      updatedData: {
        password: null,
        updated_password: null,
        updated_password_confirmation: null,
      },
    }
  },

  computed: {
    ...mapGetters({
      user: 'currentUser',
    }),

    changePasswordFormValidator() {
      return Yup.object().shape({
        password: Yup.string()
          .required()
          .nullable()
          .label("Current password"),
        updated_password: Yup.string()
          .min(6)
          .required()
          .nullable()
          .label("Password"),
        updated_password_confirmation: Yup.string()
          .min(6)
          .required()
          .nullable()
          .oneOf([Yup.ref("updated_password"), null], "Passwords must match")
          .label("Password Confirmation")
      });
    },
  },

  methods: {
    ...mapActions({
      updateUserPassword: 'user/updatePassword',
    }),

    getUpdatePayload() {
      const payload = {};
      Object.keys(this.updatedData).forEach(key => {
        if (this.updatedData[key] == null) return;
        payload[key] = this.updatedData[key];
      });
      return payload;
    },

    saveChanges() {
      const payload = this.getUpdatePayload();
      if(Object.keys(payload).length === 0) return;

      this.isSaving = true;
      this.updateUserPassword(payload)
        .then(() => {
          PopupMessage.show('Password updated successfully', 'success', 'Ok');
        })
        .catch(() => {
          PopupMessage.show('Failed to update password', 'error', 'Ok');
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
  }

});
