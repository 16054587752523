<template>
  <profile-details />
  <internal-auth />
<!--  <connected-accounts />-->
<!--  <email-preferences />-->
<!--  <notifications />-->
  <delete-account />

</template>

<script lang="ts">
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

import ProfileDetails from '@/views/profile/settings/ProfileDetails.vue';
import InternalAuth from '@/views/profile/settings/InternalAuth.vue';
// import ConnectedAccounts from '@/views/profile/settings/ConnectedAccounts.vue';
// import EmailPreferences from '@/views/profile/settings/EmailPreferences.vue';
// import Notifications from '@/views/profile/settings/Notifications.vue';
import DeleteAccount from '@/views/profile/settings/DeleteAccount.vue';


export default defineComponent({
  name: "account-settings",
  components: {
    ProfileDetails,
    InternalAuth,
    // ConnectedAccounts,
    // EmailPreferences,
    // Notifications,
    DeleteAccount,
  },

  mounted() {
    setCurrentPageBreadcrumbs("Manage Profile", []);
  }
  // setup() {
  //   const submitButton1 = ref<HTMLElement | null>(null);
  //   const submitButton2 = ref<HTMLElement | null>(null);
  //   const submitButton3 = ref<HTMLElement | null>(null);
  //   const submitButton4 = ref<HTMLElement | null>(null);
  //   const submitButton5 = ref<HTMLElement | null>(null);
  //   const updateEmailButton = ref<HTMLElement | null>(null);
  //   const updatePasswordButton = ref<HTMLElement | null>(null);
  //
  //   const emailFormDisplay = ref(false);
  //   const passwordFormDisplay = ref(false);
  //
  //
  //

  //   const saveChanges2 = () => {
  //     if (submitButton2.value) {
  //       // Activate indicator
  //       submitButton2.value.setAttribute("data-kt-indicator", "on");
  //
  //       setTimeout(() => {
  //         submitButton2.value?.removeAttribute("data-kt-indicator");
  //       }, 2000);
  //     }
  //   };
  //
  //   const saveChanges3 = () => {
  //     if (submitButton3.value) {
  //       // Activate indicator
  //       submitButton3.value.setAttribute("data-kt-indicator", "on");
  //
  //       setTimeout(() => {
  //         submitButton3.value?.removeAttribute("data-kt-indicator");
  //       }, 2000);
  //     }
  //   };
  //
  //   const saveChanges4 = () => {
  //     if (submitButton4.value) {
  //       // Activate indicator
  //       submitButton4.value.setAttribute("data-kt-indicator", "on");
  //
  //       setTimeout(() => {
  //         submitButton4.value?.removeAttribute("data-kt-indicator");
  //       }, 2000);
  //     }
  //   };
  //
  //   const deactivateAccount = () => {
  //     if (submitButton5.value) {
  //       // Activate indicator
  //       submitButton5.value.setAttribute("data-kt-indicator", "on");
  //
  //       setTimeout(() => {
  //         submitButton5.value?.removeAttribute("data-kt-indicator");
  //
  //         Swal.fire({
  //           text: "Email is successfully changed!",
  //           icon: "success",
  //           confirmButtonText: "Ok",
  //           buttonsStyling: false,
  //           customClass: {
  //             confirmButton: "btn btn-light-primary"
  //           }
  //         }).then(() => {
  //           emailFormDisplay.value = false;
  //         });
  //       }, 2000);
  //     }
  //   };
  //
  //   const updateEmail = () => {
  //     console.log(updateEmailButton.value);
  //
  //     if (updateEmailButton.value) {
  //       // Activate indicator
  //       updateEmailButton.value.setAttribute("data-kt-indicator", "on");
  //
  //       setTimeout(() => {
  //         updateEmailButton.value?.removeAttribute("data-kt-indicator");
  //
  //         emailFormDisplay.value = false;
  //       }, 2000);
  //     }
  //   };
  //
  //   const updatePassword = () => {
  //     if (updatePasswordButton.value) {
  //       // Activate indicator
  //       updatePasswordButton.value.setAttribute("data-kt-indicator", "on");
  //
  //       setTimeout(() => {
  //         updatePasswordButton.value?.removeAttribute("data-kt-indicator");
  //
  //         Swal.fire({
  //           text: "Password is successfully changed!",
  //           icon: "success",
  //           confirmButtonText: "Ok",
  //           buttonsStyling: false,
  //           customClass: {
  //             confirmButton: "btn btn-light-primary"
  //           }
  //         }).then(() => {
  //           passwordFormDisplay.value = false;
  //         });
  //       }, 2000);
  //     }
  //   };
  //
  //   const removeImage = () => {
  //     profileDetails.value.avatar = "media/avatars/blank.png";
  //   };
  //
  //   onMounted(() => {
  //     setCurrentPageBreadcrumbs("Settings", ["Account"]);
  //   });
  //
  //
  // }
});
</script>
